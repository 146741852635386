/* !
 * jQuery index v1.0.0
 * Copyright 2019, Connecty Inc.
 */
(function($, FUNCTIONS){
	const a = 'is-active';
	const v = 'is-visible';

	console.log(a);
	console.log(v);



})(window.jQuery, window.FUNCTIONS);
